// Random Background Photo
// document.addEventListener('DOMContentLoaded', function onLoadedHelper() {
  var bgImgClasses = ['bgImg0', 'bgImg1', 'bgImg2', 'bgImg3'];

  let classIndex = Math.floor(Math.random() * bgImgClasses.length);
  let theBackgroundEl = document.querySelector('#randomBGPic');

  if (theBackgroundEl && classIndex) {
    theBackgroundEl.classList.add(bgImgClasses[classIndex]);
  }
// });
