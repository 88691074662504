
import { modal } from 'uikit';

// Various INSTRUCTOR Helper Scripts
// Getting started Hide/Show
  var gStoggle = document.getElementById('GStoggle');
  if (gStoggle) {
    gStoggle.addEventListener('click', function(e) {
      e.preventDefault();
      if (e.target.innerHTML === 'Show') {
        e.target.innerHTML = 'Hide';
      } else {
        e.target.innerHTML = 'Show';
      }
    });
  }

  document.querySelectorAll('.dbq-switcher').forEach((el, idx)=>{
    window.addEventListener('hashchange', setActive, false);
    function setActive(event){
      let hashVal = parseInt(window.location.hash.replace('#', '')) || 0
      // console.log('The hash has changed!', event, hashVal)
      el.querySelectorAll('li')
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      let active = el.querySelector('li[active]');
      active?.removeAttribute('active');
      active?.querySelector('video')?.pause();
      el.querySelectorAll('li')[hashVal].setAttribute('active', '');
      document.querySelector(`a[href="#${hashVal}"]`).setAttribute('active', '');
    }
    setActive(null);
  });

document.querySelectorAll('ul#langul').forEach((el, idx) => {
  window.addEventListener('hashchange', setActive, false);
  function setActive(event) {
    let hashVal = parseInt(window.location.hash.replace('#', '')) || 0
    el.querySelectorAll('li')
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    let active = el.querySelector('li.uk-active')
    active?.classList.remove('uk-active');
    el.querySelectorAll('li')[hashVal].classList.add('uk-active');
    document.querySelectorAll('.lang-switcher').forEach((el, iii)=>{
      let children = el.querySelectorAll('li');
      // console.log("Hi : : setActive -> children", children, iii)
      children.forEach((child, inx)=>{
        if (inx === hashVal){
          child.classList.add('uk-active');
        }else{
          child.classList.remove('uk-active');
        }
      })
    });
  }
  setActive(null);
});

document.querySelectorAll('.library-assign').forEach((el) => {
  el.addEventListener('click', (event) => {
    event.preventDefault();
    var unit = event.target.closest('a');
    var url = unit.dataset.url;
    document.querySelector('#library-class-assign-dropdown').dataset.url = url;
    var modal = document.querySelector('#library-assign-modal').open();
    document.querySelector('#library-class-assign-dropdown').addEventListener('input', (event) => {
      var select = event.target;
      var val = select.value
      var url = select.dataset.url;
      // consosle.log('url', url);
      var href = url.replace('0000', val); // added fake course id to django url template tag
      document.querySelector('#library-assign-unit-href').setAttribute('href', href);
    });

    document.querySelector('#library-assign-cancel').addEventListener('click', () => {
      document.querySelector('#library-assign-modal').setAttribute('hidden', true);
    })

  });
});


// taking advantage of dbq online feedback modal
document.querySelectorAll(".online-feedback-watch").forEach((el) => {
  el.addEventListener("click", (event) => {
    event.preventDefault();
    document.querySelector("#online-feedback-modal").open();
    document.querySelector(".uk-modal-close").addEventListener("click", () => {
      document
        .querySelector("#online-feedback-modal")
        .setAttribute("hidden", true);
      document
        .querySelector("video").pause();
    });
  });
});

// taking advantage of dbq online feedback modal
document.querySelectorAll(".online-feedback-watch").forEach((el) => {
  el.addEventListener("click", (event) => {
    event.preventDefault();
    document.querySelector("#online-feedback-modal").open();
    document.querySelector(".uk-modal-close").addEventListener("click", () => {
      document
        .querySelector("#online-feedback-modal")
        .setAttribute("hidden", true);
      document
        .querySelector("video").pause();
    });
  });
});


document.querySelectorAll(".open-share-modal").forEach((el) => {
  el.addEventListener("click", (event) => {
    event.preventDefault();
    const shareButton = event.target.closest("a");
    let title = shareButton.dataset.title;
    let shareUrl = shareButton.dataset.shareUrl;
    let mailTo = shareButton.getAttribute('href');
    const shareTemplate = getShareTemplate(event, title, mailTo, shareUrl);

    // open modal
    let shareModal = modal(shareTemplate);
    shareModal.show();
    // close modal applied after modal created
    shareTemplate.querySelector(".uk-modal-confirm").addEventListener("click", (e) => {
      shareModal.hide();
    });
  });
});

document.querySelectorAll(".share-template-dropItem").forEach((el) => {
  el.addEventListener("click", (event) => {
    event.preventDefault();
    fetch(event.target.getAttribute('href'), {
      method: 'GET',
      credentials: 'same-origin'
    }).then((response) => {
      return response.status === 500 ? response : response.json();
    }).then((data) => {
      let templateMessage = document.querySelector('#templateMessage');
      let title = event.target.dataset.title;
      if (data.status === 500) {
        templateMessage.updateStyles({'--paper-toast-background-color': 'red'})
        templateMessage.text = `${title} has error: ${data.statusText}`;
      } else {

        let hashId = data.hashId;
        let shareUrl = `${event.target.dataset.shareUrl}${data.hashId}/`

        // to make the mailto link work it must be converted to dom element
        let mailToHtml = `<a href="mailto:?&amp;subject=DBQ: Shared Assignment Template&amp;body=Hello %0D%0A %0D%0A The following assignment template is being shared with you: %0D%0A %0D%0A ${title} %0D%0A ${shareUrl} %0D%0A %0D%0A Click on the link to add the assignment to your Shared Assignments folder in DBQ Online. NOTE: YOU MUST BE LOGGED INTO DBQ ONLINE FOR THIS SHARE TO WORK."</a>`
        const el = document.createElement('html');
        el.innerHTML = mailToHtml;
        let mailTo = el.getElementsByTagName('a')[0].getAttribute('href');

        const shareTemplate = getShareTemplate(event, title, mailTo, shareUrl);
        let shareModal = modal(shareTemplate);
        shareModal.show();

        // close modal applied after modal created
        shareTemplate.querySelector(".uk-modal-confirm").addEventListener("click", (e) => {
          shareModal.hide();
        });
      }
      templateMessage.open();
    });
  });
});


function getShareTemplate(event, title, mailTo, shareUrl){
  console.log('shareUrl', shareUrl);
  console.log('getShareTemplate');
  const shareModal = document.querySelector('#shareTemplateModal');
  //let confirmbutton = shareModal.getElementsByClassName('open-email')[0];
  const sharelink = document.querySelector('#sharelink');
  sharelink.value = shareUrl;
  shareModal.getElementsByTagName('strong')[0].innerHTML = title;
  shareModal.getElementsByClassName('open-email')[0].href = mailTo;

  shareModal.getElementsByClassName('copybutton')[0].addEventListener("click", (e) => {
    navigator.clipboard.writeText(sharelink.value);
    document.getElementById("copy").innerText = "Copied!";
    setTimeout(function () {
      document.getElementById("copy").innerText = "Copy";
    }, 1200);
  });

  return shareModal;

}
